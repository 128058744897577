var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8pkz1whMgxcHnVAC1H1vX"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/services/kb-client-nextjs/dist";

import * as Sentry from '@sentry/nextjs';
import { SENTRY } from './constants';

Sentry.init({
  dsn: SENTRY.DSN,
  tracesSampleRate: 1.0,
  release: process.env.APP_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      shouldCreateSpanForRequest: (url) => {
        if (url.includes('static.parastorage.com')) {
          return false;
        }
        return true;
      },
    }),
  ],
});
